import { App, AppPlugin } from "@capacitor/app";
import { Capacitor, Plugin, PluginListenerHandle, registerPlugin } from "@capacitor/core";
import { PushNotifications, PushNotificationsPlugin } from "@capacitor/push-notifications";
import { FCM, FCMPlugin } from "@capacitor-community/fcm";
import { BackgroundTask, BackgroundTaskPlugin } from "@capawesome/capacitor-background-task";
import { isDefined } from "@clipboard-health/util-ts";
import { isPlatform } from "@ionic/core";
import { BackgroundMode, BackgroundModeOriginal } from "@ionic-native/background-mode";
import { Diagnostic, DiagnosticOriginal } from "@ionic-native/diagnostic";
import { RadarSdkConfigOnSiteTrackingVariant } from "@src/appV2/FeatureFlags";
import { getAppInfo, isAndroidPlatform, isCapacitorPlatform, isIosPlatform } from "@src/appV2/lib";
import {
  APP_V2_APP_EVENTS,
  ErrorLogger,
  EventLogger,
  logError,
  logEvent,
} from "@src/appV2/lib/analytics";
import { isOsAlwaysAllowLocationPermission } from "@src/appV2/Location";
import type { RadarTrackCallback } from "capacitor-radar";
import moment from "moment-timezone";

import {
  CommuteMethod,
  RemoveListener,
  TripEvents,
  TripTrackingTrigger,
} from "./locationTracking.types";
import { CbhMainApi, cbhMainApi } from "../api/cbh-main-api/cbh-main-api.service";
import {
  LocationTrackingMode,
  LocationTrackingModeEnum,
  TripOptions,
} from "../api/cbh-main-api/cbh-main-api.types";
import { LocationService, locationService } from "../openShifts/urgentShifts/locationService";
import { LocalStorage } from "../store/session";

const RemotePnPlugin = registerPlugin<Plugin | undefined>("RemotePnPlugin");

export class LocationTrackingHelper {
  constructor(
    private readonly locationService: LocationService,
    private readonly cbhMainApi: CbhMainApi,
    private readonly logEvent: EventLogger,
    private readonly logError: ErrorLogger,
    private readonly appPlugin: AppPlugin,
    private readonly backgroundMode: BackgroundModeOriginal,
    private readonly backgroundTask: BackgroundTaskPlugin,
    private readonly remotePnPlugin: typeof RemotePnPlugin,
    private readonly fcmPlugin: FCMPlugin,
    private readonly pushNotification: PushNotificationsPlugin,
    private readonly diagnostic: DiagnosticOriginal
  ) {}

  handleTrackOnce(): Promise<RadarTrackCallback> {
    return this.locationService.trackOnce();
  }

  public async handleTrackingModeChange(params: {
    agentId: string;
    trigger: TripTrackingTrigger;
    onLocationTrackingModeChange?: (trackingConfiguration: LocationTrackingMode) => void;
  }): Promise<void> {
    const { trigger, onLocationTrackingModeChange, agentId } = params;
    let tripOptions: TripOptions | undefined;

    try {
      const platform = Capacitor.getPlatform();
      const trackingConfiguration = await this.cbhMainApi.getLocationTrackingMode(
        agentId,
        platform
      );
      onLocationTrackingModeChange?.(trackingConfiguration);

      const { mode: trackingMode, trackingOptions } = trackingConfiguration;
      ({ tripOptions } = trackingConfiguration);
      const { foregroundService } = trackingOptions ?? {};

      if (isDefined(tripOptions)) {
        tripOptions.metadata.trigger = trigger;
        tripOptions.metadata.platform = platform;
        tripOptions.metadata.trackingMode = trackingMode;
        const appInfo = await getAppInfo();
        tripOptions.metadata.appVersion = `${appInfo.version}-${appInfo.build}`;
      }

      if (trackingMode === LocationTrackingModeEnum.stop) {
        this.locationService.stopTracking();
        this.logEvent(APP_V2_APP_EVENTS.TRIP_TRACKING_STOPPED, { agentId, trigger });

        return;
      }

      const isLocationAvailable = await this.locationService.isLocationAvailable();

      if (!isLocationAvailable) {
        if (isDefined(tripOptions)) {
          this.logEvent(APP_V2_APP_EVENTS.TRIP_TRACKING_NOT_CALLED, {
            shiftId: tripOptions.metadata.shiftId,
            shiftStart: tripOptions.metadata.shiftStart,
            workerId: agentId,
            trigger,
            reason: "Location is not available",
            trackingMode,
          });
        }

        return;
      }

      const permission = await this.diagnostic.getLocationAuthorizationStatus();
      const isAlwaysAllowPermission = await isOsAlwaysAllowLocationPermission(permission);

      if (!isAlwaysAllowPermission) {
        if (isDefined(tripOptions)) {
          this.logEvent(APP_V2_APP_EVENTS.TRIP_TRACKING_NOT_CALLED, {
            shiftId: tripOptions.metadata.shiftId,
            shiftStart: tripOptions.metadata.shiftStart,
            workerId: agentId,
            trigger,
            reason: "Always allow location permission is not granted",
            trackingMode,
          });
        }
        return;
      }

      if (!isDefined(trackingOptions)) {
        if (isDefined(tripOptions)) {
          this.logEvent(APP_V2_APP_EVENTS.TRIP_TRACKING_NOT_CALLED, {
            shiftId: tripOptions.metadata.shiftId,
            shiftStart: tripOptions.metadata.shiftStart,
            workerId: agentId,
            trigger,
            reason: "Tracking options is empty",
            trackingMode,
          });
        }

        return;
      }

      this.locationService.setUserId(agentId);

      if (isDefined(tripOptions)) {
        await this.locationService.startTrip({ options: tripOptions });

        this.logEvent(APP_V2_APP_EVENTS.TRIP_TRACKING_STARTED, {
          shiftId: tripOptions.metadata.shiftId,
          shiftStart: tripOptions.metadata.shiftStart,
          workerId: agentId,
          trigger,
          trackingMode,
        });

        await this.cbhMainApi.startHyperTrackTrip({
          shiftId: tripOptions.metadata.shiftId,
          workerId: agentId,
          trigger,
          trackingMode,
        });
      }

      this.locationService.startTracking(trackingOptions, foregroundService);
    } catch (error) {
      this.logError(APP_V2_APP_EVENTS.TRIP_TRACKING_ERROR, {
        error,
        metadata: {
          shiftId: tripOptions?.metadata.shiftId,
          shiftStart: tripOptions?.metadata.shiftStart,
          workerId: agentId,
          trigger,
        },
      });
    }
  }

  public async registerAppOpenEventListener(agentId: string): Promise<RemoveListener> {
    if (!isCapacitorPlatform()) {
      // Return a empty listener remove instead of undefined to keep
      // consistency and simplify code that makes use of this function
      return () => {};
    }

    await this.handleTrackingModeChange({
      agentId,
      trigger: TripTrackingTrigger.appOpen,
    });

    const listener = await this.appPlugin.addListener("appStateChange", async (state) => {
      if (!state.isActive) {
        return;
      }

      await this.handleTrackingModeChange({
        agentId,
        trigger: TripTrackingTrigger.appOpen,
      });
    });

    return listener.remove;
  }

  public async registerSilentNotificationListener(params: {
    agentId: string;
    shouldSkipIosBackgroundTask: boolean;
  }): Promise<RemoveListener> {
    const { agentId, shouldSkipIosBackgroundTask } = params;
    if (!isCapacitorPlatform()) {
      // Return a listener remove instead of undefined to keep
      // consistency and simplify code that makes use of this function
      return () => {};
    }

    const pushNotificationListener = await this.pushNotification.addListener(
      "pushNotificationReceived",
      async ({ data }) => {
        if (!isDefined(data.commute)) {
          return;
        }

        await this.handleSilentNotification({ agentId, data, shouldSkipIosBackgroundTask });
      }
    );

    // iOS
    let remoteNotificationListener: PluginListenerHandle | undefined;
    if (isIosPlatform()) {
      if (this.remotePnPlugin?.addListener) {
        remoteNotificationListener = await this.remotePnPlugin.addListener(
          "OnRemoteNotification",
          async ({ data }) => {
            await this.handleSilentNotification({ agentId, data, shouldSkipIosBackgroundTask });
          }
        );
      } else {
        this.logError(APP_V2_APP_EVENTS.FAILED_TO_ADD_IOS_SILENT_NOTIFICATION_LISTENER, {
          error: new Error("Remote Push Notification plugin is not available"),
        });
      }
    }

    return () => {
      pushNotificationListener.remove();
      remoteNotificationListener?.remove();
    };
  }

  public async registerLocationEventListener(
    trackingOptions: RadarSdkConfigOnSiteTrackingVariant
  ): Promise<RemoveListener> {
    if (!isCapacitorPlatform()) {
      // Return a listener remove instead of undefined to keep
      // consistency and simplify code that makes use of this function
      return () => {};
    }

    return this.locationService.addLocationEventListener(async (result) => {
      if (!result.events?.some((event) => event.type === TripEvents.arrived)) {
        return;
      }

      const osSpecificTrackingOptions = isPlatform("android")
        ? trackingOptions.android
        : trackingOptions.ios;

      this.locationService.startTracking(osSpecificTrackingOptions);
    });
  }

  public async handleCommuteTopicSubscription(agentId: string): Promise<void> {
    try {
      if (!isCapacitorPlatform() || !isDefined(agentId)) {
        return;
      }

      const permission = await this.pushNotification.checkPermissions();
      const topic = `commute-${agentId}`;

      if (permission.receive !== "granted") {
        await this.fcmPlugin.unsubscribeFrom({ topic });

        return;
      }

      await this.fcmPlugin.subscribeTo({ topic });
    } catch (error) {
      this.logError(APP_V2_APP_EVENTS.HANDLE_COMMUTE_TOPIC_SUBSCRIPTION_FAILURE, {
        error,
      });
    }
  }

  public async handleSilentNotification(params: {
    agentId: string;
    data: Record<string, unknown>;
    shouldSkipIosBackgroundTask: boolean;
  }): Promise<void> {
    const { agentId, data, shouldSkipIosBackgroundTask } = params;
    try {
      const { method } = data;

      if (!isDefined(method)) {
        this.logError(APP_V2_APP_EVENTS.HANDLE_SILENT_PUSH_NOTIFICATION_ERROR, {
          error: new Error("Silent push notification method is not defined"),
        });

        return;
      }

      this.logEvent(APP_V2_APP_EVENTS.TRIP_TRACKING_SILENT_NOTIFICATION_RECEIVED, {
        workerId: agentId,
        method: method,
      });

      if (method !== CommuteMethod.startTracking) {
        this.logEvent(APP_V2_APP_EVENTS.TRIP_TRACKING_SILENT_NOTIFICATION_DISCARDED, {
          workerId: agentId,
          method: method,
          reason: "MethodNotStartTracking",
        });
        return;
      }

      await this.handleBackgroundMode({
        callback: async () => {
          const shouldDiscard = this.discardSilentNotification();

          if (shouldDiscard) {
            this.logEvent(APP_V2_APP_EVENTS.TRIP_TRACKING_SILENT_NOTIFICATION_DISCARDED, {
              workerId: agentId,
              method: method,
              reason: "ShouldDiscard",
            });
            return;
          }

          await this.handleTrackingModeChange({
            agentId,
            trigger: TripTrackingTrigger.silentPushNotification,
          });
        },
        shouldSkipIosBackgroundTask,
      });
    } catch (error) {
      this.logError(APP_V2_APP_EVENTS.TRACKING_MODE_CHANGE_FAILURE, { error });
    }
  }

  /**
   * We discard silent push notifications that arrived within
   * 5 seconds of the previously executed one.
   */
  public discardSilentNotification(): boolean {
    const key = LocalStorage.TRIP_TRACKING_SILENT_PUSH_EXEC_AT;
    const value = localStorage.getItem(key);

    if (value && moment().isBefore(moment(value).add(5, "seconds"))) {
      return true;
    }

    localStorage.setItem(key, new Date().toISOString());
    return false;
  }

  public async handleBackgroundMode(params: {
    callback: () => Promise<void>;
    shouldSkipIosBackgroundTask: boolean;
  }): Promise<void> {
    const { isActive } = await this.appPlugin.getState();
    const { callback, shouldSkipIosBackgroundTask } = params;

    if (isActive) {
      await callback();
      return;
    }

    /**
     * We use background task to execute the function when the app is in the background.
     * The task needs to be explicitly terminated, to stay in the safe usage.
     * Android platform is no more supported by the library.
     */
    if (isAndroidPlatform()) {
      try {
        this.backgroundMode.setDefaults({
          title: "You have an upcoming shift",
          text: "Using your location",
        });
        this.backgroundMode.enable();
        this.backgroundMode.disableBatteryOptimizations();
        this.backgroundMode.disableWebViewOptimizations();

        await callback();
      } finally {
        this.backgroundMode.disable();
      }

      return;
    }

    if (shouldSkipIosBackgroundTask) {
      logEvent(APP_V2_APP_EVENTS.IOS_BACKGROUND_TASK_SKIPPED);
      return;
    }
    logEvent(APP_V2_APP_EVENTS.IOS_BACKGROUND_TASK_ENABLED);

    // ios
    let taskId: string;
    /**
     * If converted to `const`, the tests
     * throw ReferenceError: Cannot access 'taskId' before initialization.
     **/
    // eslint-disable-next-line prefer-const
    taskId = await this.backgroundTask.beforeExit(async () => {
      await callback();
      /**
       * https://github.com/capawesome-team/capacitor-plugins/tree/main/packages/background-task#usage
       * The documentation recommends using const, but if set to `const`, the tests
       * throw ReferenceError: Cannot access 'taskId' before initialization.
       */
      this.backgroundTask.finish({ taskId });
    });
  }
}

export const locationTrackingHelper = new LocationTrackingHelper(
  locationService,
  cbhMainApi,
  logEvent,
  logError,
  App,
  BackgroundMode,
  BackgroundTask,
  RemotePnPlugin,
  FCM,
  PushNotifications,
  Diagnostic
);
