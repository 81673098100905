import qs from "query-string";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { EmailWaiting } from "./emailWaiting";
import { loginWithLink } from "../store/session";

interface LoginRouteState {
  url?: string;
  phone?: string;
  email?: string;
  redirectPath?: string;
}

// TODO: Migrate this route to V2 and remove this file
// Expose loginWithLink function to V2 via provider
const VerifyLogin: FC<{}> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { url } = (location.state || {}) as LoginRouteState;
  const emailToVerify = localStorage.getItem("emailToVerify");
  const queryString = qs.parse(location.search);

  useEffect(() => {
    dispatch(loginWithLink(url as string, queryString.email as string));
  }, [url, queryString.email]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EmailWaiting
      email={emailToVerify || (typeof queryString?.email === "string" ? queryString?.email : "")}
      opacity={1}
    />
  );
};

export { VerifyLogin };
