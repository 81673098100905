import { isDefined } from "@clipboard-health/util-ts";
import {
  LocationTrackingMode,
  LocationTrackingModeEnum,
} from "@src/app/api/cbh-main-api/cbh-main-api.types";
import {
  calculateGeoDistanceInMiles,
  convertToGeoLocation,
  isWithinFacilityGeofence,
} from "@src/appV2/Location";
import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import { RadarUser } from "capacitor-radar";
import { Location } from "capacitor-radar/dist/esm/definitions";
import { useRef, useState } from "react";

import { useUpdateWorkerDepartureStatus } from "../../appV2/Shifts/UrgentShifts/api/useUpdateWorkerDepartureStatus";

interface LocationTrackingModeChangeParams {
  location?: Location;
  user?: RadarUser;
  trackingConfiguration: LocationTrackingMode;
}

export function useTrackWorkerLeftEarly() {
  const [locationTrackingConfig, setLocationTrackingConfig] =
    useState<LocationTrackingModeChangeParams>();
  const distanceToFacilityInMilesRef = useRef<number | undefined>();

  const { location, trackingConfiguration } = locationTrackingConfig ?? {};
  const { shiftId, mode: trackingMode } = trackingConfiguration ?? {};

  const { isSuccess: isGetShiftSuccess, data: getShiftData } = useGetShift(shiftId ?? "", {
    enabled:
      isDefined(shiftId) && isDefined(location) && trackingMode === LocationTrackingModeEnum.onsite,
  });
  const shift = isGetShiftSuccess ? getShiftData.response : undefined;

  const { mutate: updateWorkerDepartureStatus } = useUpdateWorkerDepartureStatus();

  if (isDefined(shift) && isDefined(locationTrackingConfig)) {
    const { location, user } = locationTrackingConfig;
    if (
      isDefined(location) &&
      isDefined(shift.agentId) &&
      isDefined(shift.facility.userId) &&
      !isWithinFacilityGeofence({ facilityId: shift.facility.userId, user })
    ) {
      const distanceToFacilityInMiles = calculateGeoDistanceInMiles(
        location,
        convertToGeoLocation(shift.facility.geoLocation.coordinates)
      );

      if (distanceToFacilityInMiles !== distanceToFacilityInMilesRef.current) {
        updateWorkerDepartureStatus({
          agentId: shift.agentId,
          facilityId: shift.facility.userId,
          distanceToFacilityInMiles,
        });
        distanceToFacilityInMilesRef.current = distanceToFacilityInMiles;
      }
    }
    /**
     * This is useEffect workaround. We don't want to trigger the tracking event on every render.
     * But rather only when locationTrackingConfig changes.
     * Hence, setting this to `null` avoids triggering tracking event for unnecessary re-renders.
     */
    setLocationTrackingConfig(undefined);
  }

  return { setLocationTrackingConfig };
}
