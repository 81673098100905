export enum TripTrackingTrigger {
  appOpen = "appOpen",
  locationUpdate = "locationUpdate",
  silentPushNotification = "silentPushNotification",
  urgentShiftBooking = "urgentShiftBooking",
}

export type RemoveListener = () => void;

export enum CommuteMethod {
  startTracking = "START_TRACKING",
}

export enum TripEvents {
  arrived = "user.arrived_at_trip_destination",
  started = "user.started_trip",
}

export enum RadarErrors {
  ERROR_PERMISSIONS = "ERROR_PERMISSIONS",
  ERROR_LOCATION = "ERROR_LOCATION",
  ERROR_NETWORK = "ERROR_NETWORK",
  ERROR_BAD_REQUEST = "ERROR_BAD_REQUEST",
  ERROR_UNAUTHORIZED = "ERROR_UNAUTHORIZED",
  ERROR_PAYMENT_REQUIRED = "ERROR_PAYMENT_REQUIRED",
  ERROR_FORBIDDEN = "ERROR_FORBIDDEN",
  ERROR_NOT_FOUND = "ERROR_NOT_FOUND",
  ERROR_RATE_LIMIT = "ERROR_RATE_LIMIT",
  ERROR_SERVER = "ERROR_SERVER",
  ERROR_UNKNOWN = "ERROR_UNKNOWN",
}

export interface ShiftTrip {
  shiftId: string;
  facilityId: string;
  tripId: string;
}
