import { isDefined } from "@clipboard-health/util-ts";
import { locationTrackingHelper } from "@src/app/locationTracking/locationTracking.helper";
import { useRegisterLocationUpdateListener } from "@src/app/locationTracking/useRegisterLocationUpdateListener";
import { environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useEffect } from "react";

import { locationService } from "../openShifts/urgentShifts/locationService";

export const useRegisterLocationTracking = (workerId?: string) => {
  const ldFlags = useCbhFlags();

  const isUrgentShiftsEnabled = ldFlags[CbhFeatureFlag.URGENT_SHIFTS];
  const trackingOptions = ldFlags[CbhFeatureFlag.RADAR_SDK_CONFIG_ONSITE_TRACKING];
  const shouldSkipIosBackgroundTask = useCbhFlag(CbhFeatureFlag.SKIP_LOCATION_BACKGROUND_IOS, {
    defaultValue: false,
  });

  const registerLocationUpdateListener = useRegisterLocationUpdateListener();

  useEffect(() => {
    locationService.initialize(environmentConfig.REACT_APP_RADAR_SDK_PUBLISHABLE_KEY);
  }, []);

  useEffect(() => {
    if (!isDefined(workerId)) {
      return;
    }

    locationService.setUserId(workerId);
  }, [workerId]);

  useEffect(() => {
    if (!isUrgentShiftsEnabled) {
      locationService.stopTracking();
    }
  }, [isUrgentShiftsEnabled]);

  useEffect(() => {
    if (!isDefined(workerId) || !isUrgentShiftsEnabled) {
      return;
    }

    const cleanupFunctions = [] as Array<VoidFunction>;

    const registerListeners = async () => {
      cleanupFunctions.push(await locationTrackingHelper.registerAppOpenEventListener(workerId));

      cleanupFunctions.push(
        await registerLocationUpdateListener({ agentId: workerId, shouldSkipIosBackgroundTask })
      );

      cleanupFunctions.push(
        await locationTrackingHelper.registerSilentNotificationListener({
          agentId: workerId,
          shouldSkipIosBackgroundTask,
        })
      );

      cleanupFunctions.push(
        await locationTrackingHelper.registerLocationEventListener(trackingOptions)
      );
    };

    void locationTrackingHelper.handleCommuteTopicSubscription(workerId);

    void registerListeners();

    return () => {
      cleanupFunctions.forEach((cleanupListener) => cleanupListener());
    };
  }, [
    workerId,
    trackingOptions,
    isUrgentShiftsEnabled,
    registerLocationUpdateListener,
    shouldSkipIosBackgroundTask,
  ]);
};
