import { Preferences } from "@capacitor/preferences";

import { ShiftTrip } from "./locationTracking.types";
import { LocalStorage } from "../store/session";

export class ShiftTripStorageHelper {
  constructor(private shiftTrips: Record<string, ShiftTrip> = {}) {
    (async () => {
      const storedShiftTrips = await Preferences.get({
        key: LocalStorage.SHIFT_TRIP,
      });
      const parsedShiftTrips = JSON.parse(storedShiftTrips.value as string) as Record<
        string,
        ShiftTrip
      >;
      this.shiftTrips = parsedShiftTrips || {};
    })();
  }

  async storeShiftTrips() {
    await Preferences.set({
      key: LocalStorage.SHIFT_TRIP,
      value: JSON.stringify(this.shiftTrips),
    });
  }

  // This is triggered when the user marks the point in a new turn.
  // In this case we should clean other simultaneous shift trips and keep
  // only the one currently in progress
  async cleanUpConcurrentShifts(shiftId: string): Promise<void> {
    if (this.shiftTrips[shiftId]) {
      this.shiftTrips = {
        [shiftId]: this.shiftTrips[shiftId],
      };
      await this.storeShiftTrips();
    }
  }
}

export const shiftTripStorageHelper = new ShiftTripStorageHelper();
